<template>
  <div id="roles-form" v-if="canAccess(['store-role' , 'update-role'])">
    <div class="container-fluid">
      <!-- Tabel Section -->
      <div class="table-sections">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('add-role') }}</h4>
          </template>
          <template v-slot:body>
            <b-form class="d-grid grid-cols-1 gap-5">
              <div class="feild mb-3">
                <label for="">{{ $t('name') }}</label>
                <b-form-input
                  type="text"
                  v-model="payload.name"
                  required
                ></b-form-input>
              </div>
              <div class="permissions-container">
                <b-row>
                  <b-col sm="12" md="6" class="mb-3">
                    <div class="bg-light rounded p-3">
                      <div class="permissions-filter mb-3">
                        <b-form-input
                          type="text"
                          @change="filtering"
                          v-model="filterData.name"
                          required
                          :placeholder="$t('filter-name')"
                          size="sm"
                        ></b-form-input>
                      </div>
                      <ul class="p-0" style="height: 350px; overflow-y: auto">
                        <li v-for="permission in permissions.data" :key="permission.id">
                          <b-form-checkbox :value="permission" v-model="payload.permissions" name="check-button" switch>
                            {{ permission.name }}
                          </b-form-checkbox>
                        </li>
                      </ul>
                    </div>
                  </b-col>
                  <b-col sm="12" md="6" class="mb-3">
                    <div class="bg-light rounded p-3 px-4">
                      <ul class="p-0" style="list-style-type: disc ; height: 417px; overflow-y: auto">
                        <li v-for="permission in payload.permissions" :key="permission.id">
                          <span>
                            {{ permission.name }}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </b-col>
                  <b-col cols="12">
                    <div class="controller d-flex align-items-center justify-content-end pt-3">
                      <b-button v-if="canAccess(['store-role'])" variant="primary" @click="submit" size="lg">
                        {{$t('save') }}
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-form>
          </template>
        </iq-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      filterData: {
        page: 1,
        name: '',
        take: 100
      },
      guards: [
        'Admin',
        'hotel'
      ],
      payload: {
        name: '',
        permissions: []
      }
    }
  },
  methods: {
    filtering () {
      this.getPermissions(this.filterData)
    },
    submit () {
      if (!this.$route.params.id) {
        this.addRoles({
          name: this.payload.name,
          permissionIds: this.payload.permissions.map(permission => permission.id)
        }).then(() => {
          this.$router.push('/roles')
        })
      } else {
        this.updateRoles({
          id: this.$route.params.id,
          name: this.payload.name,
          permissionIds: this.payload.permissions.map(permission => permission.id),
          guard_name: 'admin',
          _method: 'PUT'
        }).then(() => {
          this.$router.push('/roles')
        })
      }
    }
  },
  mounted () {
    if (this.$route.params.id) {
      this.showRole(
        this.$route.params.id
      ).then(() => {
        this.payload.name = this.role.name
        this.payload.permissions = this.role.permissions
      })
    }
    this.getPermissions(this.filterData)
  }
}
</script>
